(function () {
    const template = `
        <button class="btn btn-outline-dark m-4 accessibility-menu-btn" id="settings-toggle">
            <img src="/web_assets/images/accessibility.png" height="30" width="30" alt="">
        </button>
        <div class="accessibility-menu">
            <button class="btn btn-sm crossBtn" data-option="close">❌</button>
            <div id="settings-list">
                <ul style="list-style: none">
                    <li>
                        <div class="btn-toolbar" role="toolbar">
                            <div class="d-flex gap-1">
                                <button type="button"
                                    class="btn btn-primary hover-style"
                                    data-option="font-increase"
                                >
                                    <b>A<sup>+</sup></b>
                                </button>
                                <button type="button"
                                    class="btn btn-primary hover-style"
                                    data-option="font-decrease"
                                >
                                    <b>A<sup>-</sup></b>
                                </button>
                                <button type="button"
                                    class="btn btn-primary hover-style"
                                    data-option="font-default"
                                >
                                    <b>A<sup>0</sup></b>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="highlight-links"
                                id="highlight-links"
                            >
                                <b>🔦</b> Highlight Links
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="highlight-heading"
                                id="highlight-heading"
                            >
                                <b>💡</b> Highlight Heading
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="reading-guide"
                                id="reading-guide"
                            >
                                <b>📖</b> Reading Guide
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="big-cursor"
                                id="big-cursor"
                            >
                                <b>👉</b> Big Cursor
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="invert-color"
                                id="invert-color"
                            >
                                <b>💫</b> Invert Color
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="black-white"
                                id="black-white"
                            >
                                <b>✒️️</b> Black & White
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="screen-reader"
                                id="screen-reader"
                            >
                                <b>🔉</b> Read Screen
                            </button>
                        </div>
                    </li>
                    <li>
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary hover-style widget-button"
                                data-option="reset"
                                id="reset"
                            >
                                <b>🚫</b> Reset
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    `;

    const divEl = document.createElement('div');
    divEl.innerHTML = template;
    document.body.appendChild(divEl);

    const style = `
        .inverted {
            filter: invert(1);
        }

        .highlight-links a {
            background-color: yellow;
            color: black; !important;
            text-decoration-color: #0d95ad;
        }

        .black-white {
            filter: grayscale(100%);
        }

        .highlight-heading {
            background-color: yellow;
            color: #0c1527; !important;
        }

        .highlighted {
            background-color: yellow;
            color: #0c1527; !important;
        }

        .big-cursor {
            cursor: url('/widget-plugin/img/big-cursor.svg'), auto !important;
        }

        #highlighted-line{
            position: absolute;
            width: 100%;
            height: 20px;
            background-color: yellow;
            opacity: 0.5;
            z-index: 999;
        }


        .iconBtn{
            pointer-events: none;
            width: 20% !important;
            border-left:solid 3px lightcyan;
            border-top:solid 3px lightcyan;
            border-bottom:solid 3px lightcyan;
        }

        .accessibility-enable-button {
            /*background-image: linear-gradient(to right, #203a43, #2c5364);*/
            /*color: lightcyan;*/
            /*border: solid 3px lightcyan;*/
            /*border-radius: 5px;*/
            /*padding: 5px 10px;*/
            /*margin-right: 5px;*/
            /*margin-left: 5px;*/
        }

        .accessibility-enable-button:hover {
            /*background-image: linear-gradient(to right, #365969, #638791);*/
            /*color: #ffffff;*/
            /*border-radius: 5px;*/
            /*padding: 5px 10px;*/
        }


        .accessibility-menu {
            position: fixed;
            top: 50%;
            /*right: -350px;*/
            left: -350px;
            transform: translateY(-50%);
            /*background-image: linear-gradient(to right, #365969, #638791);*/
            padding: 10px;
            border: 5px solid dimgrey;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            z-index: 99999;
            background-color: rgba(0, 0, 0, 0.8);
        }

        .accessibility-menu:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        }

        .accessibility-menu ul {
            padding: 0;
        }
        .accessibility-menu li {
            margin-top: 10px;
        }
        .accessibility-menu li button {
            width: 100%;
            text-align: left;
        }

        .crossBtn {
            position: absolute;
            top: 0;
            /*left: 0;*/
            right: -30px;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            padding: 5px 5px;
            font-size: 10px;
            border: solid 3px #87288f;
            outline: none;
            background-color: #ffffff;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            width: 30px;
            height: 30px;
        }
        .crossBtn:hover {
            background-color: #ee7af8;
        }

        .accessibility-menu-btn {
            position: fixed;
            top: 25%;
            /*right: 0px;*/
            left: -12px;
            transform: translateY(-50%);
            background-image: linear-gradient(to right, #662d91, #c75bd0);
            padding: 5px;
            border: 5px solid #662d91;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            z-index: 999;
            height: 50px;
            width: 50px;
        }

        .accessibility-menu-btn:hover {
            border: 5px solid #662d91;
            background-image: linear-gradient(to right, #9d53d3, #c75bd0);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        }

        .icon-abm{
            font-size: 23px;
        }
    `;

    const styleEl = document.createElement("style");
    styleEl.innerHTML = style;
    document.head.appendChild(styleEl);

    const settingsToggle = document.getElementById('settings-toggle');
    const accessibilityMenu = document.querySelector('.accessibility-menu');

    settingsToggle.addEventListener('click', () => {
        // accessibilityMenu.style.right = '10px';
        // accessibilityMenu.style.transition = 'right 0.5s';
        // accessibilityMenu.style.visibility = 'visible';
        accessibilityMenu.style.left = '10px';
        accessibilityMenu.style.transition = 'left 0.5s';
        accessibilityMenu.style.visibility = 'visible';
    });

    const contentSelector = document.currentScript.hasAttribute('data-content')
        ? document.currentScript.getAttribute('data-content')
        : 'body';
    
    const content = document.querySelector(contentSelector);

    let inverted, linksHighlighted, highlightedHeading, blackWhite, readingGuide, isReading, bigCursor;

    document.addEventListener('DOMContentLoad', () => {
        if (sessionStorage.getItem('inverted') === 'true') {
            inverted = true;
            invertColor();
        } else {
            inverted = false;
        }
        if (sessionStorage.getItem('linksHighlighted') === 'true') {
            linksHighlighted = true;
            highlightLink();
        } else {
            linksHighlighted = false;
        }
        if (sessionStorage.getItem('headingHighlighted') === 'true') {
            highlightedHeading = true;
            highlightHeading();
        } else {
            highlightedHeading = false;
        }
        if (sessionStorage.getItem('blackWhite') === 'true') {
            blackWhite = true;
            blackAndWhite();
        } else {
            blackWhite = false;
        }
        if (sessionStorage.getItem('readingGuide') === 'true') {
            readingGuide = true;
            readingGuideSettings();
        } else {
            readingGuide = false;
        }
        if (sessionStorage.getItem('isReading') === 'true') {
            isReading = true;
            screenReaderSettings();
        } else {
            isReading = false;
        }
        if (sessionStorage.getItem('bigCursor') === 'true') {
            bigCursor = true;
            bigCursorSettings();
        } else {
            bigCursor = false;
        }
    });

    function init() {
        const buttonElements = document.querySelectorAll('.accessibility-menu button');

        Array.from(buttonElements).forEach((btnEl) => {
            if (btnEl.hasAttribute('data-option')) {
                btnEl.addEventListener('click', () => {
                    const option = btnEl.getAttribute('data-option');
                    handleAccessibilityOption(option);
                });
            }
        });
    }

    init();

    function handleAccessibilityOption(option) {
        switch (option) {
            case 'font-default':
                fontSizeManipulate('default');
                break;
            case 'font-increase':
                fontSizeManipulate('increase');
                break;
            case 'font-decrease':
                fontSizeManipulate('decrease');
                let currentFontSize2 = content.style.fontSize;
                currentFontSize2 = currentFontSize2 ? parseFloat(currentFontSize2) - 0.1 + 'em' : '0.9em';
                break;
            case 'big-cursor':
                bigCursorSettings();
                break;
            case 'screen-reader':
                screenReaderSettings();
                break;
            case 'invert-color':
                invertColor();
                break;
            case 'highlight-links':
                highlightLink();
                break;
            case 'highlight-heading':
                highlightHeading();
                break;
            case 'reading-guide':
                readingGuideSettings();
                break;
            case 'black-white':
                blackAndWhite();
                break;
            case 'reset':
                resetAccessibilitySettings();
                break;
            case 'close':
                closeAccessibilityMenu();
                break;
            default:
                break;
        }
    }

    function fontSizeManipulate(option) {
        let allText = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, a, span, td, th, label, input, textarea, select');
        allText.forEach(text => {
            let currentFontSize = text.style.fontSize;
            if (currentFontSize && currentFontSize.includes('px')) {
                currentFontSize = parseFloat(currentFontSize) / 16 + 'em';
            }
            switch (option) {
                case 'increase':
                    if (currentFontSize < '1.4em' || currentFontSize === '1em') {
                        text.style.fontSize = currentFontSize ? parseFloat(currentFontSize) + 0.1 + 'em' : '1.1em';
                    }
                    break;
                case 'decrease':
                    if (currentFontSize > '0.7em' || currentFontSize === '') {
                        text.style.fontSize = currentFontSize ? parseFloat(currentFontSize) - 0.1 + 'em' : '0.9em';
                    }
                    break;
                default:
                    text.style.fontSize = '1em';
                    break;
            }
        });
    }


    function bigCursorSettings() {
        const cursorButton = document.getElementById('big-cursor');
        if (!bigCursor) {
            document.body.classList.toggle('big-cursor');
            bigCursor = true;
            cursorButton.style.color = 'orange';
        } else {
            document.body.classList.toggle('big-cursor');
            bigCursor = false;
            cursorButton.style.color = 'lightcyan';
        }
        sessionStorage.setItem('bigCursor', bigCursor)
    }

    function screenReaderSettings() {
        const readButton = document.getElementById('screen-reader');
        if (!isReading) {
            isReading = true;
            readButton.textContent = '🔉  Stop Reading';
            readButton.style.color = 'orange';
        } else {
            isReading = false;
            readButton.textContent = '🔉  Read Screen';
            readButton.style.color = 'lightcyan';
            speechSynthesis.cancel();
        }
        sessionStorage.setItem('isReading', isReading)
    }

    function readingGuideSettings() {
        const readGuideButton = document.getElementById('reading-guide');
        if (!readingGuide) {
            readingGuide = true;
            readGuideButton.style.color = 'orange';
            if (document.getElementById('highlighted-line') === null) {
                let highlightedLine = document.createElement('div');
                highlightedLine.id = 'highlighted-line';
                document.body.append(highlightedLine);
            }
        } else {
            readingGuide = false;
            readGuideButton.style.color = 'lightcyan';
            if (document.getElementById('highlighted-line') !== null) {
                document.getElementById('highlighted-line').remove();
            }
        }
        sessionStorage.setItem('readingGuide', readingGuide)
    }

    function invertColor() {
        const invertButton = document.getElementById('invert-color');
        if (!inverted) {
            content.classList.toggle('inverted');
            inverted = true;
            invertButton.style.color = 'orange';
        } else {
            content.classList.toggle('inverted');
            inverted = false;
            invertButton.style.color = 'lightcyan';
        }
        sessionStorage.setItem('inverted', inverted)
    }

    function highlightLink() {
        const highlightLinkButton = document.getElementById('highlight-links');
        if (!linksHighlighted) {
            content.classList.toggle('highlight-links');
            linksHighlighted = true;
            highlightLinkButton.style.color = 'orange';
        } else {
            content.classList.toggle('highlight-links');
            linksHighlighted = false;
            highlightLinkButton.style.color = 'lightcyan';
        }
        sessionStorage.setItem('linksHighlighted', linksHighlighted)
    }

    function blackAndWhite() {
        const blackAndWhiteButton = document.getElementById('black-white');
        if (!blackWhite) {
            content.classList.toggle('black-white');
            blackWhite = true;
            blackAndWhiteButton.style.color = 'orange';
        } else {
            content.classList.toggle('black-white');
            blackWhite = false;
            blackAndWhiteButton.style.color = 'lightcyan';
        }
        sessionStorage.setItem('blackWhite', blackWhite)
    }

    function highlightHeading() {
        const highlightHeadingButton = document.getElementById('highlight-heading');
        if (!highlightedHeading) {
            let headings = content.querySelectorAll('h1, h2, h3, h4, h5, h6');
            headings.forEach(heading => {
                heading.classList.toggle('highlight-heading');
            });
            highlightedHeading = true;
            highlightHeadingButton.style.color = 'orange';
        } else {
            let headings = content.querySelectorAll('h1, h2, h3, h4, h5, h6');
            headings.forEach(heading => {
                heading.classList.toggle('highlight-heading');
            });
            highlightedHeading = false;
            highlightHeadingButton.style.color = 'lightcyan';
        }
        sessionStorage.setItem('highlightedHeading', highlightedHeading)
    }

    function resetAccessibilitySettings() {
        content.classList.remove('inverted', 'big-cursor', 'highlight-links', 'highlight-heading', 'black-white', 'reading-guide', 'screen-reader');
        if (highlightedHeading) {
            let headings = content.querySelectorAll('h1, h2, h3, h4, h5, h6');
            headings.forEach(heading => {
                heading.classList.remove('highlight-heading');
            });
        }
        inverted = false;
        linksHighlighted = false;
        highlightedHeading = false;
        blackWhite = false;
        isReading = false;
        readingGuide = false;
        bigCursor = false;
        sessionStorage.setItem('inverted', inverted);
        sessionStorage.setItem('linksHighlighted', linksHighlighted);
        sessionStorage.setItem('highlightedHeading', highlightedHeading);
        sessionStorage.setItem('blackWhite', blackWhite);
        sessionStorage.setItem('isReading', isReading);
        sessionStorage.setItem('readingGuide', readingGuide);
        sessionStorage.setItem('bigCursor', bigCursor);
        fontSizeManipulate('default');
        speechSynthesis.cancel();
        if (document.getElementById('highlighted-line') !== null) {
            document.getElementById('highlighted-line').remove();
        }
        const buttons = document.querySelectorAll('.widget-button');
        buttons.forEach((button) => {
            button.style.color = '#fff';
        });
    }

    function closeAccessibilityMenu() {
        const accessibilityMenu = document.querySelector('.accessibility-menu');
        // accessibilityMenu.style.right = '-350px';
        // accessibilityMenu.style.transition = 'right 0.5s';
        accessibilityMenu.style.left = '-350px';
        accessibilityMenu.style.transition = 'left 0.5s';
        setTimeout(() => {
            accessibilityMenu.style.visibility = 'hidden';
        }, 300);
    }


    let voices = [];
    if ('speechSynthesis' in window) {
        window.speechSynthesis.onvoiceschanged = function () {
            voices = window.speechSynthesis.getVoices();
            voices.forEach(voice => {
                // console.log(voice.name, voice.lang);
            });
        };
    } else {
        console.log('Your browser does not support the screen reader feature. Please use a different browser.');
    }

    function readText(text) {
        let selectedVoice = voices[1]
        // console.log('Selected Voice: ', selectedVoice.name + ' ' + selectedVoice.lang);
        if ('speechSynthesis' in window) {
            const synthesis = window.speechSynthesis;
            const utterance = new SpeechSynthesisUtterance();
            utterance.pitch = 1;
            utterance.rate = 1;
            utterance.volume = 1;
            utterance.text = text;
            utterance.voiceURI = selectedVoice.voiceURI;
            utterance.lang = selectedVoice.lang;
            synthesis.speak(utterance);
        } else {
            alert('Your browser does not support the screen reader feature. Please use a different browser.');
        }
    }

    window.addEventListener('mouseover', (event) => {
        if (readingGuide === true) {
            let mouseY = event.pageY;
            document.getElementById('highlighted-line').style.top = (mouseY) + 'px';
        }
    });

    document.body.addEventListener('mouseover', (event) => {
        if (isReading === true) {
            let textElementList = ['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'TEXTAREA'];
            let highlightedElements = document.querySelectorAll('.highlighted');
            highlightedElements.forEach(element => {
                element.classList.remove('highlighted');
            });
            const target = event.target;
            speechSynthesis.cancel();
            if (textElementList.includes(target.tagName)) {
                target.classList.add('highlighted');
                target.classList.add('highlighted');
                const text = target.textContent.trim();
                if (text) {
                    readText(text);
                }
            }
        }
    });
})();